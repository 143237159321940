<template>
  <div class="home" id="home">
      <!-- 头部 -->
      <div id="top-nav" :class="['top-nav',{'top-nav-active':navBarFixed || !isTabMainShow}]">
        <div class="main">
          <div class="menu-btn" @click="drawer = true"></div>
          <div class="center-logo"></div>
          <div>
            <div class="search"></div>
            <div class="shop-car" @click="toShop"></div>
          </div>
        </div>
        <div class="bg_top_bar" id="bg_top_bar" v-show="hasBgTopBar"></div>
      </div>
      
      <div class="tab_btn_box" id="tab_btn_box" v-show="isTabMainShow">
        <TabBtn :menuList="menuList"
                :containerListChangeVal="containerList"
                @tabChangeVal="tabChangeVal"/>
      </div>
      <!-- 侧边导航 -->
      <el-drawer
      :size="360"
      class="navList"
      :visible.sync="drawer"
      :direction="direction"
      :show-close="false">
        <div slot="title" class="nav-top">
          <div class="center-logo"></div>
          <p class="el-icon-back back-left" @click="drawer = false"></p>
        </div>
        <ul class="nav-content">
          <li 
          v-for="(item,index) in menuList" :key="index"
          @click="handleLeft(index)"
          :class="{'active':index==activeIndex}">
            {{item.name}}
          </li>
        </ul>
      </el-drawer>
      <!-- 内容区-->
      <div id="content">
        <TabMain @isClickFAQ="isClickFAQ" 
                 :containerListChangeVal="containerList"
                 v-show="isTabMainShow"/>
        <Explain v-show="!isTabMainShow"/>
        <myFooter/>
      </div>        
  </div>
</template>

<script>
import TabMain from '@/components/TabMain'
import Explain from'@/views/explain'
import myFooter from "@/components/Footer"
import TabBtn from "@/components/TabBtn"
export default {
  name: 'Home',
  components: {
    TabMain,
    Explain,
    myFooter,
    TabBtn,
  },
  data(){
    return{
      drawer:false,
      direction:'ltr',
      activePage:'01',
      activeName: 'first',
      containerList:'product',
      navBarFixed:false,
      menuList:[{
          name:'首页',
          index:1,
        },{
          name:'产品功能',
          index:2,
        },{
          name:'产品优势',
          index:3,
        },{
          name:'视频教程',
          index:4,
        },{
          name:'配件',
          index:5,
        },{
          name:'APP下载',
          index:6,
        },
        {
          name:'关于我们',
          index:7,
        },
        {
          name:'联系我们',
          index:8,
        },
      ],
      activeIndex:0,
      listBoxState: true,//点击导航栏时，暂时停止监听页面滚动
      playerUrl:'',
      isShow:false,
      scaleVal:1,
      isTabMainShow:true,
      hasBgTopBar:false,
    }
  },
  mounted(){
    let isSaveFAG = sessionStorage.getItem('TofaqShow');
    if(!isSaveFAG){
      this.isTabMainShow = true;
    }else{
      this.isTabMainShow = isSaveFAG === 'true'? false:true;
    }
    
    let timeId,
    that = this;
    window.addEventListener('scroll',()=>{
      this.changeTabPosition();
      clearTimeout(timeId);
      this.changeTopMenu();
      timeId = setTimeout(() => {
        this.scrollScreen();
      //  console.log('执行完了哦');
      }, 100);
    }, true)
    this.$erd.listenTo(document.getElementById("home"), function (element) {
      let dom = document.getElementById("content"),
          top = document.getElementById("top-nav"),
          tabBtnBox = document.getElementById('tab_btn_box'),
          banner = document.getElementById('page1');
      let screenWidth = document.body.clientWidth;
      let screenHeight = dom.clientHeight;
      let scaleX = Number((screenWidth / 1920).toFixed(2));
      that.scaleVal = scaleX;
      let scaleFunc = "scale(" + scaleX + "," + scaleX + ")" + "translateZ(0)";
      dom.style.transform = scaleFunc;
      top.style.transform = scaleFunc;
      tabBtnBox.style.transform = scaleFunc;
      tabBtnBox.style.top = ((banner.clientHeight-tabBtnBox.clientHeight/2)*scaleX)+'px';
      dom.style.transformOrigin = "left top";
      top.style.transformOrigin = "left top";
      tabBtnBox.style.transformOrigin = "left top";
      
      that.$nextTick(()=>{
        element.style.height = `${screenHeight * scaleX}px`;
        that.changeTabPosition();
      })
      
    })
    
  },
  watch:{
    isTabMainShow:{
      handler(val){
        if(!val){
           window.scrollTo(0,0);
          // document.documentElement.scrollTop = 0;
          // document.body.scrollTop = 0;
          // window.pageYOffset = 0;
        }
        
      }
    },
  },
  methods:{
    //导航吸顶
    changeTopMenu(){
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if(scrollTop>50){
        this.navBarFixed = true
      }else{
        this.navBarFixed = false
      }
      
    },
    //tab吸顶
    changeTabPosition(){
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop,
          bannerHeight = document.getElementById("page1").clientHeight,
          tabBtn = document.getElementById('tab_btn_box'),
          navTopH = document.getElementById("top-nav").clientHeight,
          bgTopBarH = document.getElementById('bg_top_bar').clientHeight,
          MaxVal = (bannerHeight-navTopH)*this.scaleVal;
          //console.log(navTopH,'-------')
      if(scrollTop > MaxVal){
        tabBtn.style.position = 'fixed';
        tabBtn.style.top = ((navTopH-bgTopBarH*2)*this.scaleVal)+'px';
        this.hasBgTopBar = true;
      }else{
        tabBtn.style.position = 'absolute';
        tabBtn.style.top = ((bannerHeight-tabBtn.clientHeight/2)*this.scaleVal) +'px';
        this.hasBgTopBar = false;
      }
      //console.log(tabBtn,scrollTop,MaxVal)
    },
    //页面滚动后对应导航选中
    scrollScreen(){
      if(!this.isTabMainShow){
        return false;
      }
      // 获取视窗高度
      //let domHight = document.documentElement.clientHeight || document.body.clientHeight;
      let bannerHeight = document.getElementById("page1").clientHeight;
      let maxHeight = bannerHeight-bannerHeight/3;
      // dom滚动位置
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      
      if (this.listBoxState) {//作用是点击导航栏时，不执行。
        this.$nextTick(()=>{
          for(let i = 0;i<this.menuList.length;i++){
            if(this.$el.querySelector(`#page${this.menuList[i].index}`)){
              let offsetTop = this.$el.querySelector(`#page${this.menuList[i].index}`).offsetTop;
              //console.log(i,offsetTop,scrollTop, document.body.scrollHeight)
              if((offsetTop-scrollTop/this.scaleVal) < maxHeight){
                  this.activeIndex = i;
                  //break;
                }
            }
          }
        })
      }
    },
    //点击导航
    handleLeft(val){
      this.isTabMainShow = true;
      sessionStorage.setItem('TofaqShow',false);
      this.activeIndex=val;
      this.drawer = false;
      
      if(this.menuList[val].name=='关于我们' || this.menuList[val].name=='联系我们'){
        this.containerList='about'
      }else{
        this.containerList='product'
      }
      this.$aos.refreshHard();console.log('yunxing---')
      this.$nextTick(()=>{
          //console.log(this.$el.querySelector(`#page${this.menuList[val].index}`))          
          this.$el.querySelector(`#page${this.menuList[val].index}`).scrollIntoView({
            behavior: "smooth",  // 平滑过渡
            block:    "start"  // 上边框与视窗顶部平齐。默认值
          });
        })
      this.listBoxState=false;
      let timeId;
      clearTimeout(timeId);
      timeId = setTimeout(() => {
        this.listBoxState=true;
      },1000);
    },
    //tab切换
    tabChangeVal(val){
      this.changeTopMenu();
      this.changeTabPosition();
      this.handleLeft(val);
      this.$nextTick(()=>{
        let screenHeight = document.getElementById("content").clientHeight
        document.querySelector('#home').style.height = `${screenHeight * this.scaleVal}px`;
      })
    },
    //淘宝链接
    toShop(){
      window.open('https://shop310257023.taobao.com','_blank')
    },
    isClickFAQ(){
      this.isTabMainShow = false;
    },
  }
}
</script>
<style lang="scss">
.navList{
  .el-drawer__header{
    padding: 0;
  }
  .el-drawer{
    background: #000;
  }
}

</style>
<style scoped lang="scss">
.home{
  position: relative;
  #content{
    width:1920px;
  }
  .top-nav{
    .main{
      height: 170px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    width:1920px;
    opacity: 0.8;
    padding: 0 320px;   
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    z-index: 999;
    .menu-btn{
      width: 36px;
      height: 24px;
      cursor: pointer;
      background: url('~@/assets/images/menu.png') no-repeat;
      background-size: contain;
    }
    .center-logo{
      width: 76px;
      height: 77px;
      background: url('~@/assets/images/logo.png') no-repeat;
      background-size: contain;
    }
    .search{
      width: 24px;
      height: 24px;
      margin-right: 21px;
      display: inline-block;
      cursor: pointer;
      background: url('~@/assets/images/search.png') no-repeat;
      background-size: contain;
    }
    .shop-car{
      width: 27px;
      height: 24px;
      display: inline-block;
      cursor: pointer;
      background: url('~@/assets/images/shopCar.png') no-repeat;
      background-size: contain;
    }
    .bg_top_bar{
      //width:1920px;
      height: 20px;
    }
  }
  .top-nav-active{
    background: #000000;
  }
  .navList{
    .nav-top{
      display: flex;
      align-items: center;
      padding: 35px 31px 35px 110px;
      box-sizing: border-box;
      .center-logo{
        width: 78px;
        height: 80px;
        background: url('~@/assets/images/logo2.png') no-repeat;
        background-size: contain;
      }
      .back-left{
        margin: -24px 0 0 95px;
        font-size: 45px;
        color: #fff;
      }
    }
    .nav-content{
      li{
        width: 247px;
        height: 54px;
        font-size: 18px;
        color: #fff;
        line-height: 54px;
        text-indent: 124px;
        cursor: pointer;
      }
      
    }
    .active{
      width: 247px;
      height: 54px;
      font-weight: 600;
      background: #DC4707;
      border-radius: 0px 100px 100px 0px;
      cursor: pointer;
    }
  }
  .tab_btn_box{
    position: absolute;
    top:170px;
    z-index: 1000;
    width:1920px;
    height: 62px;
    background: transparent;
    text-align: center;
  }
  
}
</style>
