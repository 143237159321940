<template>
<!-- 内容区-->
    <div>
        <div class="container">
          <!--banner轮播-->
          <div class="banner" id="page1">
            <swiper ref="mySwiper" :options="swiperOptions" class="swiper-container">
              <swiper-slide>
                <div class="img-1"></div>
                <div class="banner-btn-g">
                  <el-button class="banner-btn banner-btn-bg2" @click="toShop">淘宝官方店</el-button>
                  <el-button class="banner-btn" @click="playVideo(0)">播放视频</el-button>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="img-2"></div>
                <div class="banner-btn-g">
                  <el-button class="banner-btn banner-btn-color2 banner-btn-bg3" @click="toShop">淘宝官方店</el-button>
                  <el-button class="banner-btn" @click="playVideo(1)">播放视频</el-button>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="img-3"></div>
                <div class="banner-btn-g">
                  <el-button class="banner-btn banner-btn-bg2" @click="toShop">淘宝官方店</el-button>
                  <el-button class="banner-btn" @click="playVideo(2)">播放视频</el-button>
                </div></swiper-slide>
              <swiper-slide>
                <div class="img-4"></div>
                <div class="banner-btn-g">
                  <el-button class="banner-btn banner-btn-bg2" @click="toShop">淘宝官方店</el-button>
                  <el-button class="banner-btn" @click="playVideo(3)">播放视频</el-button>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="img-5"></div>
                <div class="banner-btn-g">
                  <el-button class="banner-btn banner-btn-bg4" @click="toShop">淘宝官方店</el-button>
                  <el-button class="banner-btn" @click="playVideo(4)">播放视频</el-button>
                </div>
              </swiper-slide>
              <div class="swiper-scrollbar" slot="scrollbar"></div>
            </swiper>
            <div class="now-page">{{activePage}}</div>
            <div class="all-page">05</div>
          </div>
          <!--内容区切换-->
          <div>
            <div :is="containerList" @TofaqShow="TofaqShow"></div>
          </div>
        </div>
        <!-- 视频播放器 -->
        <Player v-show="isShow" @closeFlag="closeFlag" :isShow="isShow" :playerUrl="playerUrl"/>
    </div>
</template>

<script>
import { swiper, swiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.min.css'
import product from '@/components/product'
import about from '@/components/about'
import Player from '@/components/player'
export default {
  name: 'Home',
  components: {
    swiper,
    swiperSlide,
    product,
    about,
    Player
  },
  directives: {
    swiper: directive
  },
  props: {
    containerListChangeVal: String
  },
  data(){
    let that = this
    return{
      drawer:false,
      direction:'ltr',
      activePage:'01',
      swiperOptions: {
        direction: 'vertical',
        slidesPerView: 'auto',
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },
        observer: true,
        observeParents: true,
        scrollbar: {
          el: '.swiper-scrollbar',
          //draggable: true,
          dragSize:71,
        },
        //mousewheel: true,
        on:{
          slideChangeTransitionEnd:()=>{
            that.activePage = `0${(this.$refs.mySwiper.swiper.activeIndex)+1}`;
          },
        }
      },
      playerUrl:'',
      isShow:false,
      scaleVal:1,
      containerList: "product"
    }
  }, 
  watch: {
    containerListChangeVal: {
      handler(val) {
        this.containerList = val;
      },
      immediate: true
    }
  },
  methods:{
    //视频播放
    playVideo(){
      this.playerUrl = 'http://1306303804.vod2.myqcloud.com/587d3c5evodcq1306303804/1dc28cc8387702296746785567/ZYztSQjkMxUA.mp4';
      this.isShow = !this.isShow;
    },
    //视频关闭
    closeFlag(data){
      this.isShow = data;
    },
    
    //淘宝链接
    toShop(){
      window.open('https://shop310257023.taobao.com','_blank')
    },
    TofaqShow(val){
        this.$emit('isClickFAQ',val);
    }
  }
}
</script>
<style lang="scss">
.banner-btn-g{
  position: absolute;
  top: 590px;
  left: 382px;
  .banner-btn{
    width: 208px;
    height: 62px;
    padding: 0;
    margin-right: 10px;
    font-size: 16px;
    background: #fff;
    border-radius: 31px;
    border: none;
    &:first-child::after{
      content: "";
      display: inline-block;
      width: 25px;
      height: 22px;
      margin-top: -4px;
      margin-left: 6px;
      vertical-align: middle;
      background: url("~@/assets/images/shopCar.png") no-repeat;
      background-size: contain;
    }
    &:last-child{
      color: #fff;
      background: none;
      border: 2px solid #fff;
      &::after{
        content: "";
        display: inline-block;
        width: 20px;
        height: 23px;
        margin-top: -4px;
        margin-left: 6px;
        vertical-align: middle;
        background: url("~@/assets/images/playIcon.png") no-repeat;
        background-size: contain;
      }
    }
  }
  .banner-btn-bg2{
    background: #DC4707;
    color: #fff;
  }
  .banner-btn-bg3{
    &::after{
      background: url("~@/assets/images/shopCar2.png") no-repeat !important;
      background-size: contain !important;
    }
  }
  .banner-btn-bg4{
    color: #90A771;
    &::after{
      background: url("~@/assets/images/shopCar3.png") no-repeat !important;
      background-size: contain !important;
    }
  }
  .banner-btn-color2{
    color: #BD120E;
  }
}
.swiper-container-vertical>.swiper-scrollbar{
  width: 2px;
  height: 355px;
  left: 326px;
  top: 303px;
  right: inherit;
  border-radius: 0;
  background: rgba(255,255,255,.27);
}
.swiper-scrollbar-drag{
  background: #fff;
  border-radius: 0;
}

</style>
<style scoped lang="scss">
  .container{
    .banner{
      width: 100%;
      height: 960px;
      .swiper-container{
        width: 100%;
        height: 100%;
        .img-1,
        .img-2,
        .img-3,
        .img-4,
        .img-5{
          width: 100%;
          height: 100%;
        }
        .img-1{
          background: url('~@/assets/images/banner1.png') no-repeat;
          background-size: contain;
        }
        .img-2{
          background: url('~@/assets/images/banner2.png') no-repeat;
          background-size: contain;
        }
        .img-3{
          background: url('~@/assets/images/banner3.png') no-repeat;
          background-size: contain;
        }
        .img-4{
          background: url('~@/assets/images/banner4.png') no-repeat;
          background-size: contain;
        }
        .img-5{
          background: url('~@/assets/images/banner5.png') no-repeat;
          background-size: contain;
        }
      }
      .now-page,.all-page{
        position: absolute;
        top: 268px;
        left: 322px;
        color: #fff;
        font-size: 12px;
        z-index: 1;
        font-weight: 600;
      }
      .all-page{
        top: 678px;
      }
    }
  }

</style>