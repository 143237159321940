
<template>
  <div class="footer_page">
    <div class="main_box">
        <img class="logo" src="@/assets/images/footer/footer_logo.png" alt="">
        <div class="contact_box">
            <img src="@/assets/images/footer/footer_title.png" alt="" class="title">
            <img src="@/assets/images/footer/contect_me_code.png" alt="" class="code">
        </div>
        <div class="share_box">
            <el-popover
                placement="top-end"
                width="150"
                trigger="hover"
                v-for="(item,index) in shareList" :key="index">
                <img :src="item.code" alt="" style="display:block;width:150px;">
                <a class="share_icon" slot="reference">
                    <img :src="item.icon" alt="">
                </a>
            </el-popover>
            <a class="share_icon" href="https://shop310257023.taobao.com" target="_blank">
                <img :src="require('@/assets/images/footer/tao_white.png')" alt="">
            </a>
        </div>
    </div>
    <div class="bottom_box">
        <div class="left_box">
            <p style="margin-bottom:7px;"><a href="https://www.blinktech.com.cn/privacy/index.html" target="_blank">隐私权政策 · 使用条款</a></p>
            <p><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">版权© 2021 Blink Tech -保留所有权利。鄂ICP备2021012493号-1</a></p>
        </div>
        <div class="right_box">
            <img src="@/assets/images/footer/language.png" alt="">
            <a href="#" class="language_cn"></a>
            <a href="#" class="language_en"></a>
        </div>
    </div>
  </div>
</template>

<script>
import iconShare from "@/assets/images/footer/share_white.png"
import iconDou from "@/assets/images/footer/douyin_white.png"
import iconBili from "@/assets/images/footer/bilibili_white.png"
import codeShare from "@/assets/images/footer/share_code.png"
import codeDou from "@/assets/images/footer/douyin_code.png"
import codeBili from "@/assets/images/footer/bilibili_code.png"
export default {
    data(){
        return{
            mail:'',
            shareList:[
                {
                    icon:iconShare,
                    code:codeShare,
                    title:'不认识',
                },
                {
                    icon:iconDou,
                    code:codeDou,
                    title:'抖音',
                },
                {
                    icon:iconBili,
                    code:codeBili,
                    title:'哔哩哔哩',
                },
            ],
        }
    }
}
</script>
<style lang="scss">
.el-popper{
    padding: 0;
    border-radius: 8px;
}
</style>
<style scoped lang="scss">
.footer_page{
    background:#4D4D4D;
    padding:66px 0 42px 0;
    .main_box{
        width:1170px;
        margin:0 auto;
        overflow: hidden;
        padding-bottom:5px;
        .logo{
            display: inline-block;
            width:152px;
        }
        .contact_box{
            display: inline-block;
            margin-left:135px;
            font-family: PingFang SC-Light, PingFang SC;
            .title{
                width:50%;
                display: block;
            }
            .code{
                width:16%;
                margin-top:20px;
                display: block;
            }
            
        }
        .share_box{
            display: block;
            margin:160px 0 0 50px;
            float: right;
            img{
                width:100%;
            }
            .share_icon{
                display: inline-block;
                width:20px;
                margin-left:25px;
                margin-bottom:10px;
                cursor:pointer;
            }
            
        }

    }
    .bottom_box{
        width:1170px;
        display: flex;
        justify-content: space-between;
        margin:0 auto;
        border-top:solid 1px rgba(255,255,255, 0.1);
        padding-top:21px;
        color:#969696;
        font-size:14px;
        p{
            a{
                color: inherit;
                text-decoration: none;
            }
        }
        img{
            display: inline-block;
            width:152px;
        }
        .right_box{
            position: relative;
            a{
                display: inline-block;
                width:60px;
                height:20px;
                position: absolute;
                z-index:1;
            }
            .language_cn{               
                left:23px;
                top:0px;
            }
            .language_en{
                right:0px;
                top:0px;
            }
        }
    }
    
}
</style>

