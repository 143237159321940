<template>
  <div class="home_main_page">
    <!-- 产品功能 -->
    <section id="page2">
        <!-- 介绍1 -->
        <div class="introduce_box1">
            <div class="main">
                <div class="left">
                    <img src="@/assets/images/Home/introduce01_title.png" alt=""
                     data-aos="fade-in"
                     data-aos-duration="300"
                     aos-anchor-placement="top-top"
                     />
                    <p data-aos="slide-right" data-aos-duration="400" data-anchor-placement="top-top">
                        Blink Focos 是 Blink 公司⾃主研发的，世界上⼀款可以⾃动拍摄团队⽐赛的⼿机跟拍系统。</p>
                    <p data-aos="slide-right" data-aos-duration="400" data-anchor-placement="top-top">
                        基于⼈⼯智能技术，Focos 可以深度理解运动场景、智能识别跟踪目标，并实现实时分析拍摄。公司独创的“蛙眼跟踪”算法拥有多项中国和美国专利技术，可以为⽤户打造出⾼精度、低延迟的拍摄效果。</p>
                </div>
                <div class="right">
                    <img src="@/assets/images/Home/introduce01_img01.png" alt=""
                    data-aos="flip-right" data-aos-duration="700" data-anchor-placement="top-top">
                </div>
                
            </div>
            <div class="bg"></div>
            <div class="bg2">
                <img src="@/assets/images/Home/introduce01_img02.png" alt="">
            </div>
            <div class="bg3">
                <img src="@/assets/images/about/about11.png" alt="">
            </div>
        </div>
        <!-- 介绍2 -->
        <div class="introduce_box2">
            <div class="title_box">
                <img src="@/assets/images/Home/introduce02_title.png" alt=""
                    data-aos="fade-up" 
                    data-aos-duration="100"
                    >
            </div>
            <div class="main">
                <div class="left">
                    <img src="@/assets/images/Home/introduce02_left.png" alt=""
                    data-aos="fade-down" 
                    data-aos-duration="400"                    
                    >
                </div>
                <div class="right">
                    <img src="@/assets/images/Home/introduce02_right.png" alt=""
                    data-aos="fade-left" 
                    data-aos-duration="500"
                    >
                </div>
            </div>
        </div>
        <!-- 介绍3 -->
        <div class="introduce_box3" data-aos="fade-up" data-duration="400">
            <img src="@/assets/images/Home/introduce03.png" alt="">
        </div>
        <!-- 介绍4 -->
        <div class="introduce_box4" data-aos="fade-up" data-duration="400">
            <img src="@/assets/images/Home/introduce04.png" alt="">
        </div>
    </section>
    <!-- 产品优势 -->
    <section id="page3">
        <!-- 示意图 -->
        <div class="shiyitu" data-aos="fade-up" data-duration="400">
            <img src="@/assets/images/Home/shiyitu.png" alt="">
        </div>
        <!-- 介绍5 -->
        <div class="introduce_box5">
            <div class="main">
                <div class="left">
                    <img src="@/assets/images/Home/introduce05_left01.png" alt="" class="img01"
                        data-aos="slide-right"
                        data-aos-duration="600">
                    <img src="@/assets/images/Home/introduce05_left02.png" alt="" class="img02"
                        data-aos="zoom-in"
                        data-aos-duration="800"
                        >
                </div>
                <div class="right">
                    <div class="top">
                        <img src="@/assets/images/Home/introduce05_right01.png" alt="" class="img01"
                        data-aos="flip-left"
                        data-aos-duration="600"
                        >
                        <img src="@/assets/images/Home/introduce05_right02.png" alt="" class="img02"
                        data-aos="flip-left"
                        data-aos-duration="800"
                        >
                    </div>    
                    <div class="bottom">
                        <i class="bg_gray_ball"></i>
                        <i class="bg_orange_ball"></i>
                        <i class="bg_red_ball"></i>
                        <img src="@/assets/images/Home/introduce05_right03.png" alt="" class="img03"
                        data-aos="flip-left"
                        data-aos-duration="600"
                        >
                        <img src="@/assets/images/Home/introduce05_right04.png" alt="" class="img04"
                        data-aos="zoom-in"
                        data-aos-duration="600"
                        >
                    </div>            
                    
                </div>
            </div>
            <div class="bg"></div>
        </div>
        <!-- 更多功能 -->
        <div class="more_box" data-aos="fade-up" data-duration="400">
            <img src="@/assets/images/Home/more.png" alt="">
        </div>
    </section>
    <!-- 轮播-视频教程 -->
    <div class="swiper_box" id="page4" data-aos="fade-up" data-duration="400">
        <a href="https://www.focos.tech/doc/cn/BlinkFocos.pdf" target="_blank" class="btn">Blink Focos 使用手册</a>
        <div class="jump_url">
            <a class="question" @click="Tofaq">常见问题FAQ</a>
            <a>｜</a>
            <a target="_blank" href="https://www.focos.tech/doc/cn/BlinkGimbal.pdf" class="book">《Blink Gimbal 手机稳定器用户手册》</a>
        </div>
        <div class="product-swiper">
            <transition-group name="flip-list" tag="ul">
                <li v-for="curImg in currImgs" v-bind:key="curImg">
                    <div v-if="curImg===0">
                        <div class="slide-1"></div>
                        <div 
                        class="video_btn"
                        @click="playVideo(0)">
                            <img src="@/assets/images/Home/rotation_btn.png" alt="">
                        </div>
                        <div class="ani text-1"></div>
                    </div>
                    <div v-if="curImg===1">
                        <div class="slide-2">
                        </div>
                        <div 
                        class="video_btn"
                        @click="playVideo(1)">
                            <img src="@/assets/images/Home/rotation_btn.png" alt="">
                        </div>
                        <div class="text-2"></div>
                    </div>
                    <div v-if="curImg===2">
                        <div class="slide-3">
                        </div>
                        <div 
                        class="video_btn"
                        @click="playVideo(2)">
                            <img src="@/assets/images/Home/rotation_btn.png" alt="">
                        </div>
                        <div class="text-3"></div>
                    </div>
                    <div v-if="curImg===3">
                        <div class="slide-4">
                        </div>
                        <div 
                        class="video_btn"
                        @click="playVideo(3)">
                            <img src="@/assets/images/Home/rotation_btn.png" alt="">
                        </div>
                        <div class="text-4"></div>
                    </div>
                    <div v-if="curImg===4">
                        <div class="slide-5"></div>
                        <div class="video_btn"
                        @click="playVideo(4)">
                            <img src="@/assets/images/Home/rotation_btn.png" alt="">
                        </div>
                        <div class="text-5"></div>
                    </div>
                </li>
            </transition-group> 
            <div class="swiper-button-prev" @click="preTrans">
                <i class="icon left"></i>
            </div>
            <div class="swiper-button-next" @click="nextTrans">
                <i class="icon right"></i>
            </div>
        </div>
    </div>
    <!-- 搭配 -->
    <div class="dapei_box" id="page5">
        <img src="@/assets/images/Home/dapei_bg.png" alt="" class="bg" 
             data-aos="fade-in"             
             >
        <img src="@/assets/images/Home/dapei_img02.png" alt="" class="img02"  
            data-aos="flip-up"
            data-duration="200"    
            >
        <img src="@/assets/images/Home/dapei_img01.png" alt="" class="img01"
            data-aos="zoom-out" 
            data-duration="200" 
        >
    </div>
    <!-- APP下载 -->
    <div class="download_app" id="page6" data-aos="fade-up" data-duration="400">
        <img src="@/assets/images/Home/download_app.png" alt="">
    </div>
    <!-- 视频播放器 -->
    <Player v-show="isShow" @closeFlag="closeFlag" :isShow="isShow" :playerUrl="playerUrl"/>
  </div>
</template>

<script>
import Player from '@/components/player'
export default {
    data(){
        return{
            isShow:false,
            plaryerUrlList:[
                {
                    title:'云台打开与折叠',
                    src:'http://1306303804.vod2.myqcloud.com/587d3c5evodcq1306303804/8b506617387702292235645179/zo8WA13PbSwA.mp4',
                    //src:"http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4",
                },
                {
                    title:'云台固定到三脚架',
                    src:'http://1306303804.vod2.myqcloud.com/587d3c5evodcq1306303804/8d93937a387702292235735839/AhS2BhK6N5kA.mp4',
                },
                {
                    title:'拍摄位置选择',
                    src:'http://1306303804.vod2.myqcloud.com/587d3c5evodcq1306303804/8b3b9411387702292235630287/8VwBG3I1bQkA.mov',
                },
                {
                    title:'云台开机关机',
                    src:'http://1306303804.vod2.myqcloud.com/587d3c5evodcq1306303804/8b05f073387702292235601885/u8DCNeakHUYA.mp4',
                },
                {
                    title:'蓝牙连接',
                    src:'http://1306303804.vod2.myqcloud.com/587d3c5evodcq1306303804/8bab79bb387702292235696474/fUAWTpsAfqoA.mov',
                },
            ],
            playerUrl:'',
            currImgs: [],
            imgs: [0,1,2,3,4],
            index: 0,
            timer:null,
        }
    },
    components:{
        Player,
    },
    mounted: function () {
        this.currImgs = [this.imgs[0]];
        this.startChange();
    },
    beforeDestroy(){
        if(this.timer){
            clearInterval(this.timer)
        }
    },
    methods:{
        startChange() {
            var _this = this;
            this.timer = setInterval(function () {
                if (_this.index < _this.imgs.length - 1) {
                    _this.index++
                } else {
                    _this.index = 0
                }
                //console.log(_this.index)
                _this.currImgs.splice(0, 1, _this.imgs[_this.index]);
            }, 1500);
        },
        playVideo(index){
            this.playerUrl = this.plaryerUrlList[index].src;
            this.isShow = !this.isShow;
        },
        closeFlag(data){
            this.isShow = data;
        },
        Tofaq(){
            // let routeData = this.$router.resolve({
            //     name: "Explain",
            // });
            // window.open(routeData.href, '_blank')
            this.$emit('TofaqShow',true);
            sessionStorage.setItem('TofaqShow',true);
        },
        preTrans(){
            if(this.timer){
                clearInterval(this.timer)
            }
            var _this = this;
            if(_this.index === 0){
                _this.index = _this.imgs.length - 1
            }else{
                _this.index--
            }
            //console.log(_this.index)
            _this.currImgs.splice(0, 1, _this.imgs[_this.index]);
            this.startChange()
        },
        nextTrans(){
            if(this.timer){
                clearInterval(this.timer)
            }
            var _this = this;
            if (_this.index < _this.imgs.length - 1) {
                _this.index++
            } else {
                _this.index = 0
            }
            _this.currImgs.splice(0, 1, _this.imgs[_this.index]);
            this.startChange()
        },
    },

}
</script>

<style scoped lang="scss">
.flip-list-enter-active, .flip-list-leave-active {
    transition: all 1s;
}
.flip-list-enter, .flip-list-leave-active {
    opacity: 0;
}
.home_main_page{
    background: #fff;
}
img{
    width:100%;
    font-size:0px;
    display: inline-block;
}
.download_app{
    padding:130px 0 0 0;
    background:#fff;
    height: 854px;
    img{
        
        height: 100%;
        width:auto;
    }
}
.dapei_box{
    position:relative;
    padding:130px 0 0 0;
    height: 849px;
    .bg{
        width: 75%;
    }  
    .img01{
        width:61%;
        position:absolute;
        z-index:3;
        right:26%;
        bottom:0;
    }
    .img02{
        width:35%;
        position:absolute;
        z-index:2;
        right:14%;
        bottom:50px;
    }
}
.more_box{
    background:#fff;
    text-align: center;
    padding:130px 0;
    height:370px;
    img{
        height:100%;
        width:auto;
    }
}
.shiyitu{
    background:#fff;
    text-align: center;
    padding:0 0 130px 0;
    height: 630px;
    img{
        height:100%;
        width:auto;
    }
}
.introduce_box1{
    padding:90px 0 70px 0;
    position: relative;
    height: 630px;
    overflow: hidden;
    .main{
        width:60%;
        margin:0 auto;
        position: relative;
        z-index:4;
        overflow: hidden;
        .left{
            float:left;
            width:43%;
            margin-top:80px;
            img{
                width:100%;
                margin-bottom:0px;
            }
            p{
                font-size:18px;
                color:#4D4D4D;
                line-height: 23px;
                padding:45px 0 0 0;
            }
        }
        .right{
            float:right;
            width:55%;
        }
    }
    .bg{
        position:absolute;
        z-index:0;
        background:#EFEFEF;
        width: 70%;
        height: 100%;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        left:0px;
        top:0px;
    }
    .bg2{
        position:absolute;
        z-index:2;
        left:44%;
        bottom:0;
        width:17%;
    }
    .bg3{
        position:absolute;
        z-index:2;
        right:20%;
        top:29%;
        width:8%;
    }
}
.introduce_box2{
    margin-top:100px;
    height: 1194px;
    .title_box{
        width:38%;
        margin:0 auto;
    }
    .main{
        position: relative;
        margin:60px 0 0 0;
        overflow: hidden;
        .left{
            position:absolute;
            left:10%;
            top:50%;
            transform: translateY(-50%);
            width:33%;
            z-index:2;
        }
        .right{
            float:right;
            width:80%;
        }
    }
}
.introduce_box3{
    background:#fff;
    text-align: center;
    padding:130px 0;
    height:1030px;
    img{
        height:100%;
        width:auto;
    }
}
.introduce_box4{
    background:#fff;
    text-align: center;
    padding:0 0 77px 0;
    height:1037px;
}
.introduce_box5{
    position: relative;
    background:#fff;
    height: 891px;
    .bg{
        background:#EFEFEF;
        position:absolute;
        bottom:0px;
        width:100%;
        left:0px;
        height:85%;
        z-index:0;
    }
    .main{
        width:58%;
        overflow: hidden;
        position: relative;
        z-index:1;
        margin:0 auto;
        padding-bottom:50px;
        .left{
            float:left;
            width:45%;
            .img02{
               margin:60px 0 0 30px; 
            }
        }
        .right{
            float:right;
            position:relative;
            width:50%;
            .top{
                position: relative;
            }
            .img01{
                width:43%;
            }
            .img02{
                width: 33%;
                position: absolute;
                bottom:0px;
                right:15%;
            }
            .bottom{
                text-align: center;
                position: relative;
            }
            .img03{
                width:43%;
                margin:25px auto;
            }
            .img04{
                width:65%;
                margin:41px 0 0 20px;
                display: block;
            }
            i{
                display: block;
                border-radius: 50%;
                position:absolute;
                z-index:1;
            }
            .bg_gray_ball{                
                width:11%;
                height: 10%;
                background:#969696;
                top:11%;
                left:0px;
            }
            .bg_orange_ball{                
                width:38px;
                height: 38px;
                background:#FE7F00;
                top:27%;
                left:15%;
            }
            .bg_red_ball{                
                width:11%;
                height: 10%;
                background:#CB2A0B;
                top:34%;
                right:10%;
            }
            
        }
    }
    
}
.swiper_box{
    position: relative;
    height: 884px;
    background:  url('~@/assets/images/Home/rotation.png') no-repeat;
    background-size: 1920px 880px;
    .product-swiper{
        width: 100%;
        height: 100%;
    }
    .swiper-button-prev, .swiper-button-next{
        width:60px;
        height: 60px;
        line-height: 67px;
        background:#F3F3F3;
        color:#DC4707;
        border-radius: 50%;
        text-align: center;
        box-sizing: border-box;
        bottom:30px;
        top:auto;
        .icon{
            display: inline-block;
            border-top:solid 5px #DC4707;
            border-left:solid 5px #DC4707;
            width:12px;
            height:12px;            
        }
        .left{
            transform: rotateZ(-45deg);
        }
        .right{
            transform: rotateZ(135deg);
        }
        
    }
    .swiper-button-prev{
        left:43%;
    }
    .swiper-button-next{
        left:49%;
    }
    .slide-1,.slide-2,.slide-3,.slide-4,.slide-5{
        width: 570px;
        height: 544px;
        position: absolute;
        top:171px;
        left:355px;
    }
    .slide-1{
        background: url('~@/assets/images/Home/rotation01.png') no-repeat;
        background-size: 100% 100%;
    }
    .slide-2{
        background: url('~@/assets/images/Home/rotation02.png') no-repeat;
        background-size: 100% 100%;
    }
    .slide-3{
        background: url('~@/assets/images/Home/rotation03.png') no-repeat;
        background-size: 100% 100%;
    }
    .slide-4{
        background: url('~@/assets/images/Home/rotation04.png') no-repeat;
        background-size: 100% 100%;
    }
    .slide-5{
        background: url('~@/assets/images/Home/rotation05.png') no-repeat;
        background-size: 100% 100%;
    }
    .text-1,.text-2,.text-3,.text-4,.text-5{
        position: absolute;
        top: 392px;
        left: 1055px;
        height: 90px;
    }
    .text-1{
        width: 320px;
        background: url('~@/assets/images/Home/rotation01-1.png') no-repeat;
        background-size: 100% 100%;
    }
    .text-2{
        width: 384px;
        background: url('~@/assets/images/Home/rotation02-1.png') no-repeat;
        background-size: 100% 100%;
    }
    .text-3{
        width: 384px;
        background: url('~@/assets/images/Home/rotation03-1.png') no-repeat;
        background-size: 100% 100%;
    }
    .text-4{
        width: 320px;
        background: url('~@/assets/images/Home/rotation04-1.png') no-repeat;
        background-size: 100% 100%;
    }
    .text-5{
        width: 256px;
        background: url('~@/assets/images/Home/rotation05-1.png') no-repeat;
        background-size: 100% 100%;
    }
    .video_btn{
        position: absolute;
        width:230px;
        height: 230px;
        z-index:2;
        top:328px;
        left:525px;
        //transform: translateY(-50%);
        cursor: pointer;
        //animation: scale_animation 2.5s infinite linear;
    }
    @keyframes scale_animation {
        0%{
            
            transform: translateY(-50%) scale(1);
        }
        50%{
            transform: translateY(-50%) scale(0.7);
            
        }
        100%{
            transform: translateY(-50%) scale(1);
            
        }
        

    }
    .jump_url{
        position:absolute;
        z-index:2;
        bottom:22%;
        right: 22.5%;
        a{
            cursor: pointer;
            font-size:18px;
            color:#4D4D4D;
            text-decoration: none;
        }
        span{
            font-size:18px;
            color:#4D4D4D;
            padding:0 0 0 3px;
        }
    }
    .btn{
        position:absolute;
        z-index:2;
        right:34%;
        bottom:34%;
        padding:7px 23px;
        display: inline-block;
        font-size:18px;
        color:#fff;
        text-decoration: none;
        cursor: pointer;
        border-radius: 30px;
        background:#DC4707;
    }
}
</style>