<template>
  <el-button-group class="tab-btn-group" id="tab_btn">
    <el-button
      :class="{activeName:containerList=='product'}"
      @click="handleClick('product')"
    >产品功能 · FEATURES</el-button>
    <el-button
      :class="{activeName:containerList=='about'}"
      @click="handleClick('about')"
    >关于我们 · ABOUT US</el-button>
  </el-button-group>
</template>

<script>
export default {
  props: {
    menuList: Array,
    containerListChangeVal: String
  },
  data() {
    return {
      activeName: "first",
      containerList: "product"
    };
  },
  watch: {
    menuList: {
      handler(val) {
        return val;
      },
      immediate: true
    },
    containerListChangeVal: {
      handler(val) {
        this.containerList = val;
      },
      immediate: true
    }
  },
  methods: {
    //产品功能&关于我们切换
    handleClick(val) {
      let type =
        val == "about" ? "关于我们" : val == "product" ? "产品功能" : "";
      for (let i = 0; i < this.menuList.length; i++) {
        if (this.menuList[i].name == type) {
          this.$emit("tabChangeVal", i);
          //this.handleLeft(i)
          return;
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.tab-btn-group {
  .el-button {
    width: 470px;
    height: 62px;
    font-size: 16px;
    color: #fff;
    padding: 0;
    background: #4d4d4d;
    border: none;
    border-radius: 31px 0px 0px 31px;
  }
  .el-button + .el-button {
    width: 470px;
    height: 62px;
    border-radius: 0px 31px 31px 0px;
  }
  .activeName {
    background: #dc4707;
  }
}
</style>