<template>
<div id="playerCover">
    <a id="player_close_btn" @click="closeEvent">
        <i class="el-icon-close"></i>
    </a>
    <div class="player_box" id="player" v-if="isShow">
        <vue-core-video-player :src="playerSrc"></vue-core-video-player>
    </div>
</div>
</template>

<script>
export default {
    props:{
        isShow:Boolean,
        playerUrl:String,
    },
    data(){
        return{
            playerSrc:'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4',
        }
    },
    watch:{
        isShow:{
            handler(val){
                if(val){
                    this.playerSrc = this.playerUrl;
                    let parentEle = document.body;
                    parentEle.style.overflow = 'hidden';
                }
            }
        },
        playerUrl:{
            handler(val){
                this.playerSrc = val;
            }
        },
    },
    
    mounted(){
        this.init();
    },
    methods:{
        init(){
            let parentEle = document.body,
                player = document.getElementById('playerCover');
            parentEle.appendChild(player);
                        
        },
        closeEvent(){
            this.$emit('closeFlag',false);
            let parentEle = document.body;
            parentEle.style.overflow = null;
            console.log('close---')
        },
        
    }
}
</script>

<style scoped lang="scss">
#playerCover{
    position: fixed;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background:rgba(0,0,0,.9);
    z-index: 2000;
    overflow: hidden;
    #player_close_btn{
        position:absolute;
        right:20px;
        top:20px;
        font-size:40px;
        color:#fff;
        cursor:pointer;
    }
}
.player_box{
    position: absolute;
    width:65%;
    left:50%;
    top:52%;
    transform: translate(-50%,-50%);
}
</style>
