<template>
    <div>
        <section id="page7" style="height
        :2286px">
            <div class="part1">
                <div class="part1-left"
                ></div>
                <div class="part1-right"
                >
                    <img :src="require('@/assets/images/about/about6.png')" class="img1"/>
                    <div>
                        <p>深眸科技（北京）有限公司（简称Blink公司），专注于“⼿机智能摄像”功能，基于智能⼿机和AI技术对体育运动进⾏智能跟踪拍摄和数据分析。创始团队均在美国取得硕博士学位，在美国亚马逊尖端硬件实验室、贝尔实验室、清华大学等有超过10年的相关研发经验。</p>
                        <p>公司研发的智能手机拍摄云台Blink Focos是世界上第一套利用智能手机自动完成团队体育比赛的追踪拍摄、直播和剪辑系统，拥有多项发明专利技术。公司愿景是通过前沿科技让体育拍摄更轻松，目标为草根体育提供便捷而专业的精彩视频。</p>
                        <p>Blink公司成立于2021年10月，成立之初即数次获得奖项：</p>
                        <ul>
                            <li>
                                · ⼯信部“创客北京”⼈⼯智能⼗强创新企业
                            </li>
                            <li>
                                · GCEA 全球华⼈创业⼤赛“深科技”第三名
                            </li>
                            <li>
                                · 教育部“春晖杯”留学人员创新创业大赛优胜奖
                            </li>
                            <li>
                                · 清华大学“三创⼤赛”全球总决赛优胜奖
                            </li>
                            <li>
                                · 亚⻢逊全球“云创计划”创新企业
                            </li>
                            <li>
                                · 苏州工业园区“创业领军人才”企业
                            </li>
                        </ul>
                    </div>
                    <img :src="require('@/assets/images/about/about2.png')" class="img2"/>
                </div>
            </div>
            <div class="part2">
                <div class="part2-left"
                >
                    <img :src="require('@/assets/images/about/about8.png')"/>
                    <p>
                        谈科峰博士，Blink深眸科技创始人，中国国防科技大学通信工程学士及美国加州大学计算机科学博士。
                    </p>
                    <p>
                        谈博士在硅谷有多年智能硬件、软件、无线互联网领域的研发应用和管理经验。曾任美国亚马逊的前沿硬件实验室Lab 126技术负责人，负责设计并实现了亚马逊第一代智能电视、智能音箱、机器人等产品中的关键模块-无线互联算法，成功推动产品销售遍布全球。在高通任职期间，曾带领团队研发出世界首例基于通用WiFi路由器并取得分米级精度的室内定位原型系统，多次被授予“高通之星”荣誉。谈博士曾任职于美国贝尔实验室、美国AT&T香农实验室和美国NEC中央研究院从事研究工作，获得多项国际学术大奖。2021年度入选“苏州工业园区科技领军人才“，并获得工信部“启明计划”国家级创新人才支持。
                    </p>
                </div>
                <div class="part2-right">
                    <dl>
                        <dt>
                            <span>创始人</span>
                            <h6>谈科峰（博⼠）</h6>
                        </dt>
                        <dd>
                            <img :src="require('@/assets/images/about/about3.png')"/>
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <span>联合创始⼈</span>
                            <h6>张依真（博⼠）</h6>
                        </dt>
                        <dd>
                            <img :src="require('@/assets/images/about/about4.png')"/>
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <span>联合创始⼈</span>
                            <h6>盛阳（MBA）</h6>
                        </dt>
                        <dd>
                            <img :src="require('@/assets/images/about/about5.png')"/>
                        </dd>
                    </dl>
                </div>
            </div>
        </section>
        <!--联系我们-->
        <section id="page8" style="height:880px">
            <div class="part3">
                <div class="part3-left">
                    <img :src="require('@/assets/images/about/about9.png')" class="img2"/>
                    <p>官方邮箱：support@blinktech.us</p>
                    <p>官方微信客服<img :src="require('@/assets/images/about/about10.png')"/></p>
                    <img :src="require('@/assets/images/about/ewm1.png')" class="img3"/>
                </div>
                <div class="part3-right">
                    <el-form 
                    :model="ruleForm" 
                    :rules="rules" 
                    ref="ruleForm" 
                    label-width="0px" 
                    class="demo-ruleForm">
                        <el-form-item 
                        prop="userName">
                            <el-input v-model="ruleForm.userName" placeholder="请输入你的姓名"></el-input>
                        </el-form-item>
                        <el-form-item 
                        prop="phone">
                            <el-input v-model="ruleForm.phone" placeholder="请输入你的手机号"></el-input>
                        </el-form-item>
                        <el-form-item prop="type">
                            <el-select v-model="ruleForm.type" placeholder="请选择类型">
                                <el-option label="售前咨询" value="0"></el-option>
                                <el-option label="售后服务" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item prop="content">
                            <el-input type="textarea" v-model="ruleForm.content" placeholder="请输入你想对 Blink Focos说的话"></el-input>
                        </el-form-item>
                        <el-button @click="submitForm('ruleForm')" class="submit">提交</el-button>
                    </el-form>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import{ getUserMsg } from '@/api/home.js'
export default{
    name:'about',
    data() {
        return{
            ruleForm: {
                userName: '',
                phone:'',
                type: '',
                content: '',
            },
            rules: {
                userName: [
                    { required: true, message: '请输入你的姓名', trigger: 'blur' },
                ],
                phone: [
                    { required: true, message: '请输入你的手机号', trigger: 'blur' },
                    { pattern:/^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/, message: '请输入正确的手机号',trigger: 'blur',},
                ],
                type: [
                    { required: true, message: '请选择类型', trigger: 'change' }
                ],
                content:[
                    { required: true, message: '请输入你想对 Blink Focos说的话', trigger: 'blur'},
                ]
            }
        }
    },
    methods:{
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    getUserMsg(this.ruleForm).then((res)=>{
                        if(res.code==200){
                            this.$message({
                                message: '提交成功',
                                type: 'success'
                            });
                            this.$refs[formName].resetFields();
                        }
                    })
                } else {
                    return false;
                }
            });
        },
    }
}
</script>
<style lange="scss">
    .el-input__inner{
        height: 48px;
        padding: 0;
        padding-left: 24px;
        font-size: 18px;
        border: 1px solid #DADADA;
        border-radius: 24px;
        display: block;
    }
    .el-select{
        width: 100%;
    }
    .el-textarea__inner{
        height: 112px;
        font-size: 18px;
        padding-left: 24px;
        border-radius: 24px;
    }
    .el-form-item__error{ 
        font-size: 18px;
    }
    .el-form-item{
        margin-bottom: 32px;
    }
    input::-webkit-input-placeholder,textarea::-webkit-input-placeholder{
        font-size: 18px;
        color:#333333;
    }
    input::-moz-input-placeholder,textarea::-moz-input-placeholder{
        font-size: 18px;
        color:#333333;
    }
    input::-ms-input-placeholder,textarea::-ms-input-placeholder{
        font-size: 18px;
        color:#333333;
    }
    .submit{
        width: 170px;
        height: 48px;
        color: #fff;
        font-size: 18px;
        padding: 0;
        margin-left: 304px;
        border: none;
        background: #DC4707;
        border-radius: 24px 24px 24px 24px;
    }
</style>
<style scoped lang="scss">
    .part1{
        position: relative;
        width: 100%;
        height: 1290px;
        .part1-left{
            width: 570px;
            height: 560px;
            position: absolute;
            left: 375px;
            top: 160px;
            background: url('~@/assets/images/about/about1.png') no-repeat;
            background-size: contain;
            z-index: 1;
        }
        .part1-right{
            width: 1366px;
            height: 1290px;
            position: absolute;
            right: 0;
            top: 0;
            padding-left: 521px;
            box-sizing: border-box;
            background: #EFEFEF;
            border-radius: 80px 0px 0px 80px;
            .img1{
                display: block;
                width: 438px;
                height: 183px;
                margin: 193px 0 75px;
            }
            .img2{
                display: block;
                width: 459px;
                height: 60px;
                margin-top:25px;
            }
            p{
               width: 470px;
               margin-bottom: 40px;
               font-size: 18px;
               color: #4D4D4D; 
               line-height: 32px;
            }
            ul{
                li{
                    font-size: 18px;
                    color: #4D4D4D;
                    line-height: 32px;
                }
            }
        }
    }
    .part2{
        position: relative;
        width: 100%;
        height: 1381px;
        padding-top: 147px;
        box-sizing: border-box;
        .part2-left{
            width: 1294px;
            height: 971px;
            background: #DC4707;
            border-radius: 0px 80px 80px 0px;
            overflow: hidden;
            img{
                width: 514px;
                height: 126px;
                margin: 112px auto 130px;
                display: block;
            }
            p{
                width: 513px;
                margin: 0 auto;
                color: #fff;
                font-size: 18px;
                line-height: 32px;
                &:last-child{
                    margin-top: 30px;
                }
            }
            &::after{
                content: "";
                display: block;
                width: 191px;
                height: 484px;
                background: url("~@/assets/images/about/about7.png") no-repeat;
                background-size: contain;
                position: absolute;
                left: 1333px;
                top: 397px;
            }
        }
        .part2-right{
            height: 712px;
            position: absolute;
            left: 1026px;
            top: 325px;
            dl{
                display: flex;
                dt{
                    span{
                        display: block;
                        margin-top: 38px;
                        color: #fff;
                        font-size: 16px;
                        line-height:28px;
                    }
                    h6{
                        color: #fff;
                        font-size: 24px;
                        font-weight: normal;
                    }
                }
                dd{
                    img{
                        display: block;
                        width: 280px;
                        height: 280px;
                        margin: -48px 0 0 -41px;
                    }
                }
                &:last-child{
                    dd{
                        img{
                            margin-left: -22px;
                        }
                    }
                }
            }
        }
    }
    .part3{
        width: 100%;
        height: 880px;
        display: flex;
        background: #EFEFEF;
        overflow: hidden;
        &::after{
            content: "";
            width: 176px;
            height: 176px;
            margin: 279px 0 0 -105px;
            background: url('~@/assets/images/about/about11.png') no-repeat;
            background-size: contain;
        }
        .part3-left{
            margin: 228px 0 0 340px;
            img{
                display: block;
            }
            p{
                font-size: 18px;
                color: #4D4D4D;
                line-height: 32px;
                img{
                    display: inline-block;
                    width: 24px;
                    height: 20px;
                    margin-left: 15px;
                    vertical-align: middle;
                }
            }
            .img2{
                width: 256px;
                height: 162px;
                margin-bottom: 34px;
            }
            .img3{
                width: 214px;
                height: 214px;
                margin-top: 15px;
            }
        }
        .part3-right{
            margin: 160px 0 0 334px;
            width: 570px;
            height: 560px;
            padding: 81px 48px 0;
            background: #969696;
            border-radius: 40px 40px 40px 40px;
            box-sizing: border-box;
            z-index: 1;
        }
    }
</style>
